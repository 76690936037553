import React from 'react'
import './index.scss'
import { graphql } from 'gatsby'

import Seo from '../../components/seo'

import MainFrameCover from '../../components/MainFrameCover'
import SanitySection from '../../Sanity/SanitySection'

// import Modal from '../components/Modal'


function Page(props) {
  const { data } = props

  const page = data?.sanityPage
  const quickHTML = page?.quickHTML

  const metaTitle = props.data.sanityPost?.title
  const metaDescription = props.data.sanityPost?.metadescription

  const sections = page?.sections?.map((node,i) => {
    return (
      <SanitySection key={i} {...node} />
    )
  })


  return (
    <>
      <MainFrameCover />

      {/* <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal> */}

      <main id="mainframe" className={`active main-page`}>

        <Seo
          title={metaTitle}
          description={metaDescription}
          path={props.location?.pathname ? props.location?.pathname : ''}
        />

        {sections}

        {quickHTML ?
          <div
            dangerouslySetInnerHTML={{ __html: quickHTML.code }}
          />
        : null }
        
      </main>
    </>
  )
}

export default Page

export const query = graphql`
  query($id: String!) {
    sanityPage(id: {eq: $id}) {
      id
      slug {
        current
      }
      title
      metadescription
      quickHTML {
        code
      }
      sections {
        __typename
        id
        title
        slug {
          current
        }
        classes
        type
        quickHTML {
          code
        }
        blocks {
          ...sanityImage
          ...sanityVideo
          ...sanityContent
          ...sanitySlider
        }
      }
    }
  }
`