import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import { StaticImage } from 'gatsby-plugin-image'

function CustomBlock({inView, images: img, videos: video, contents: content, sliders: slider}) {

	return (
		<>
			<div className="flex-12">
				<div className="span-12 padd">
					<Intro visible={inView} in={{ fadeRight: 700 }} delayIn={0} mounted={true} stay={true} className="">
						<h2>Our companies</h2>
					</Intro>
				</div>
				<div className="span-9 span-12-mobile">
					<div className="box flex-12">
						<div className="span-4">
							<Intro visible={inView} in={{ border: 700, bg: 500, fade: 500 }} delayIn={0} mounted={true} stay={true} className="c5 t borderless border-c5">
								<div className="aspect-ratio">
									<StaticImage
										src={'../../../static/images/logo-dss.png'}
										layout="fixed"
										objectFit="contain"
										objectPosition={'50% 50%'}
										placeholder='NONE'
										alt="DSS logo"
										className="bg"
									/>
							</div>
								</Intro>
						</div>
						<div className="span-8 padd-half details as-center">
							<Intro visible={inView} in={{ fadeBottom: 700 }} delayIn={800} mounted={true} stay={true} className="">
								<p>DeepSleep Studio is the global production and distribution division within Made Fresh Media Group, delivering premium original programming across documentary, scripted series, factual formats and feature films.</p>
							</Intro>
						</div>
					</div>
					<div className="box flex-12 padd-top-half">
						<div className="span-4 ">
							<Intro visible={inView} in={{ border: 700, bg: 500, fade: 500 }} delayIn={200} mounted={true} stay={true} className="c5 t borderless border-c5">
								<div className="aspect-ratio">
									<StaticImage
										src={'../../../static/images/logo-signal.png'}
										layout="fixed"
										objectFit="contain"
										objectPosition={'50% 50%'}
										placeholder='NONE'
										alt="Signal logo"
										className="bg"
									/>
								</div>
							</Intro>
						</div>
						<div className="span-8 padd-half details as-center">
							<Intro visible={inView} in={{ fadeBottom: 700 }} delayIn={1000} mounted={true} stay={true} className="">
								<p>Signal is the global Public Relations division within Made Fresh Media Group, connecting the dots and spreading the word across the MFD eco system. </p>
							</Intro>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CustomBlock