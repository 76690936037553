import React from 'react'
import './style.scss'

import { useInView } from "react-intersection-observer"

import SanityImage from '../SanityImage'
import SanityVideo from '../SanityVideo'
import SanityContent from '../SanityContent'
import SanitySlider from '../SanitySlider'
import SanityCustom from '../Custom/CUSTOM'


function SanitySection(props) {
	const {id, slug, classes, blocks, type, quickHTML} = props
	//console.log('Image:',title, slides)

  const [refSection, refSectionInView] = useInView({ triggerOnce: true })

  const blocksRoute = type ?
    <SanityCustom {...props} refSectionInView={refSectionInView} />
  : blocks?.map((node, i) => {
    if (node.__typename === 'SanityBuilderImage') return <SanityImage key={`image-${i}`} {...node} />
    if (node.__typename === 'SanityBuilderVideo') return <SanityVideo key={`video-${i}`} {...node} />
    if (node.__typename === 'SanityBuilderContent') return <SanityContent key={`content-${i}`} {...node} />
    if (node.__typename === 'SanityBuilderSlider') return <SanitySlider key={`slider-${i}`} {...node} />
    return false
  })


  return (
    <section key={`section-${id}`} id={slug?.current} ref={refSection} className={`sanity-section ${classes}`}>

			{slug?.current ?
				<div id={`section-${slug.current}`} className="anchor"></div>
			: null}

      {blocksRoute}

      {quickHTML?.code ?
        <div
          dangerouslySetInnerHTML={{ __html: quickHTML.code }}
        />
      : null }
      
    </section>
  )
}

export default SanitySection