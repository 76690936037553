import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import SocialLinks from '../../../components/SocialLinks'

function CustomBlock({inView, images: img, videos: video, contents: content, sliders: slider}) {

	return (
		<>
			<div id={'fitheight'} className="flex-12">
				<div className="span-8 span-12-mobile">
					<Intro visible={inView} in={{ bg: 750, fade: 750 }} delayIn={200} mounted={true} stay={true} className="video-box c5 t">
						{video[0]}
					</Intro>
				</div>

				<div className="span-4 span-12-mobile flex padd-half padd-bottom-2 ac-flex-end ai-flex-end">
					<Intro visible={inView} in={{ fadeRight: 700 }} delayIn={400} mounted={true} stay={true} className="hero-logo padd-bottom-half overflow-visible">
						{img[0]}
					</Intro>
					<SocialLinks />
				</div>
			</div>

			<div className="about flex-12 jc-flex-end">
				<div className="span-8 span-12-mobile flex ai-center jc-flex-end padd-right">
					<Intro visible={inView} in={{ fadeBottom: 700 }} delayIn={800} mounted={true} stay={true} className="padd-top-half overflow-hidden">
						<h1 className="h2 fw-300">We are a web3 media DAO<br/>on the fringe of arts &amp; tech.</h1>
					</Intro>
				</div>
			</div>
		</>
	)
}

export default CustomBlock