import React from 'react'
import './style.scss'

import Intro from '../../../animations/Intro'
import Typewriter from "typewriter-effect"

function CustomBlock({inView, images: img, videos: video, contents: content, sliders: slider}) {

	let twq = ['Stop Fucking Around', 'Three Word Quote', 'Get it done']

	return (
		<>
			<div className="twq-intro">
				<Intro
					visible={inView}
					in={{ fadeBottom: 750 }}
					delayIn={100}
					mounted={true}
					stay={true}
					className={``}
				>
					<h6 className="uppercase">3 word quote</h6>
				</Intro>
			</div>

			<div className="twq padd-2 text-center">
				<Intro
					visible={inView}
					in={{ fadeBottom: 750 }}
					delayIn={500}
					mounted={true}
					stay={true}
					className={``}
				>
					<h2 className="h1">
						<Typewriter
							options={{
								strings: twq,
								autoStart: true,
								loop: true,
								pauseFor: 4000,
								cursor: "_",
							}}
						/>
					</h2>
				</Intro>
			</div>
		</>
	)
}

export default CustomBlock